* {
  font-family: 'Merriweather', serif;
  padding: 0;
  margin: 0;
  background-color: rgb(237, 238, 240);
}

body {
  margin: 0;
  font-family: 'Merriweather', serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* main color scheme colors

dk-gray: rgb(211,211,211)
gray: rgb(222,222,222)
lt-gray: (237,238,240)
dark blue: rgb(11,39,71)

*/
.flex {
  display: flex;
}

.bg-darkblue {
  background-color: rgb(11, 39, 71);
}

.bg-darkgray {
  background-color: rgb(211, 211, 211);
}

.bg-gray {
  background-color: rgb(222, 222, 222);
}

.bg-lightgray {
  background-color: rgb(237, 238, 240);
}

.bg-white {
  background-color: white;
}

.font-white {
  color: white;
}

.height-banner {
  height: 50px;
}

.info-paragraph-tabbed {
  margin-left: 25px;
}

.margin-left-line {
  margin-left: 15px;
  border-left: 1px solid;
}

.notification-message-warning {
  background-color: gold;
  color: black;
  padding: 10px;
}

.notification-message-success {
  background-color: lightblue;
  color: black;
  padding: 10px;
}