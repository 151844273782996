.scoreboard {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.tile {
  border: 0.5px solid black;
  height: 30px;
  width: 30px;
}

.tile-wide {
  border: 0.5px solid black;
  height: 30px;
  width: 40px;
}

.left-bold {
  border-left: 3px solid black;
}

.win-hi {
  font-weight: bold;
  background-color: gold;
}