.sabr-score-box {
  border: 1px solid rgb(11, 39, 71);
  padding: 10px;
  /* max-width: 35%;
  min-width: 30%; */
  max-width: 25rem;
  min-width: 15rem;
  border-radius: 5px;
  background-color: white;
}

.sabr-score-bold {
  font-weight: bold;
  padding: 5px;
}

.sabr-score-text {
  background-color: white;
}

.sabr-score-year-title {
  font-weight: bold;
  font-size: 32pt;
}

.sabr-notification-banner {
  background-color: gold;
  padding: 8px;
}

.sabr-score-pos {
  font-size: 16pt;
  padding: 2px;
  font-weight: bold;
  width: 2rem;
}