#sb-footer {
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;




}

#sb-footer-text {
  margin: 10px;
}