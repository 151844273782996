.simulate-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.simulate-cont-left {
  /* outline: 1px dotted red; */
  border: 1px solid #00274a;
  border-radius: 15px;
  padding: 10px;
  /* max-height: 250px; */
}

.simulate-cont-right {
  /* outline: 1px dotted red; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.yearSelect {
  /* color: white;
  background-color: #00274a; */
  width: 5.5rem;
  font-size: 14pt;
  text-align: center;
  height: 2.5rem;
  outline: none;
  border-radius: 5px;
  text-indent: 0.25rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  background-color: lightgray;
}

.teamSelect {
  /* color: white;
  background-color: #00274a; */
  width: 16rem;
  font-size: 14pt;
  text-align: center;
  height: 2.5rem;
  outline: none;
  border-radius: 5px;
  text-indent: 0.25rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  background-color: lightgray;
}

.atsymb {
  font-size: 14pt;
  font-weight: bold;
  margin-left: 0.5rem;
}

.numGames {
  width: 4rem;
  border-radius: 15px;
  border: none;
  height: 2.75rem;
  font-size: 16pt;
  background-color: #00274a;
  color: white;
  outline: none;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.numGamesTxt {
  font-size: 14pt;
  font-weight: bold;
  margin-left: 0.25rem;
  margin-right: 6rem;
  margin-bottom: 0.1rem;
}

.simButton {
  color: white;
  background-color: #00274a;
  height: 2.75rem;
  font-size: 16pt;
  border-radius: 15px;
}

.simButton:hover {
  background-color: #004480;
  color: white;
}

.resultMessage {
  background-color: #00274a;
  padding: 10px;
  color: white;
  border-radius: 15px;
  margin-bottom: 10px;
}


.resultsList th {
  padding: 0.5rem;
  border-bottom: 1px solid;
  font-size: 14pt;
}

.resultsList td {
  padding: 0.5rem;
  text-align: center;
}


.resultsList tr {
  padding: 0.5rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}


.resultsList table {
  padding: 1rem;
  width: 100%;
}

.resultsList-div {
  width: 100%;
  text-align: center;
}

.resultsList-title {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.table-centered {
  margin: auto;
}

.table-font {
  font-size: 50pt;
}

.gameFontSize {
  font-size: 12pt;
  background-color: #00274a;
  color: white;
  /* min-width: 36px; */
}

.scoreFontSize {
  font-size: 16pt;
}

.resultsListScroll {
  overflow: scroll;
}